<script lang="ts" setup>
const { t } = useT();
const { open } = useNlcModals();
const RESEND_EMAIL_TIMEOUT_MILLISECONDS = 599_000;
const { emailVerificationTimerCookie } = useEmailVerificationTimerCookie();
const { duration, durationLeft, reset } = useCountdown({
	timestamp: Number(emailVerificationTimerCookie.value) + RESEND_EMAIL_TIMEOUT_MILLISECONDS,
	formatToken: "mm[ : ]ss"
});
const handleOpenEmailConfirmClick = () => {
	if (duration.value > 0) {
		return;
	}
	dispatchGAEvent({
		event: "verification",
		location: "header",
		step: "info",
		type: "email"
	});
	open("LazyOModalEmailConfirm", { immediateRequest: false });
};

watch(emailVerificationTimerCookie, (value) => {
	if (value) {
		reset(Number(emailVerificationTimerCookie.value) + RESEND_EMAIL_TIMEOUT_MILLISECONDS);
	}
});
</script>

<template>
	<div
		:class="['alert', { 'already-sent': duration > 0 }]"
		data-tid="alert-verify-email"
		@click="handleOpenEmailConfirmClick"
	>
		<NuxtIcon name="20/warning" />
		<AText as="div">
			<i18n-t keypath="An email was sent to you. Click the {key1} in your inbox to complete the sign-up! {key2}">
				<template #key1>
					<b>{{ t("verification link") }}</b>
				</template>
				<template #key2>
					<b v-if="duration > 0" class="countdown"> {{ t("Resend") }} ({{ durationLeft }}) </b>
					<AText v-else :modifiers="['underline', 'bold']">{{ t("Click to resend!") }}</AText>
				</template>
			</i18n-t>
		</AText>
	</div>
</template>

<style lang="scss" scoped>
.alert {
	display: flex;
	justify-content: center;
	align-items: baseline;
	background: var(--additional-a-3);
	transition: all 200ms ease-in-out;
	@include media-breakpoint-down(lg) {
		padding: gutter(1) gutter(2);
	}
	@include media-breakpoint-up(lg) {
		padding: gutter(2.125);
		.tempe {
			font-size: 16px;
		}
	}
	&:not(.already-sent):hover {
		cursor: pointer;
		opacity: 0.85;
	}
	.nuxt-icon {
		font-size: 20px;
		margin-right: gutter(1);
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	.countdown {
		display: inline-block;
		width: 150px;
	}
}
</style>
